let handleElement;

const bind = (el, { value }) => {
  let width, startX;
  let minWidth = value.minWidth || 0,
    maxWidth = value.maxWidth || Math.MAX_SAFE_INTEGER,
    handleWidth = value.handleWidth || 16,
    handleZIndex = value.handleZIndex || 1;

  handleElement = createHandleEl();
  el.appendChild(handleElement);

  el.addEventListener('mousedown', start);
  el.addEventListener('touchstart', start);
  el.__onStart = start; // for undinding

  function createHandleEl() {
    const handleWidthPx = handleWidth + 'px';
    const handleLeftPx =
      -handleWidth / 2 + el.getBoundingClientRect().right + 'px';
    const handleEl = document.createElement('div');
    handleEl.style.position = 'absolute';
    handleEl.style.zIndex = handleZIndex;
    handleEl.style.cursor = 'col-resize';
    handleEl.style.left = handleLeftPx;
    handleEl.style.top = 0;
    handleEl.style.bottom = 0;
    handleEl.style.width = handleWidthPx;

    return handleEl;
  }

  function start(e) {
    const isTouch = e.type === 'touchstart' && e.touches.length > 0;
    const evtData = isTouch ? e.touches[0] : e;
    startX = evtData.clientX;
    width = el.getBoundingClientRect().width;

    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', end);
    document.addEventListener('touchmove', move);
    document.addEventListener('touchend', end);
    e.preventDefault();
  }

  function move(e) {
    const isTouch = e.type === 'touchmove' && e.touches.length > 0;
    const evtData = isTouch ? e.touches[0] : e;
    let dx = evtData.clientX - startX;
    const newWidth = Math.min(maxWidth, Math.max(minWidth, width + dx));
    el.style.width = newWidth + 'px';
    handleElement.style.left = -handleWidth / 2 + newWidth + 'px';

    el.dispatchEvent(new CustomEvent('resize'))
    e.preventDefault();
  }

  function end() {
    handleElement.style.left =
      -handleWidth / 2 + parseInt(el.style.width) + 'px';
    document.removeEventListener('mousemove', move);
    document.removeEventListener('mouseup', end);
    document.removeEventListener('touchmove', move);
    document.removeEventListener('touchend', end);
  }
};

const unbind = el => {
  el.addEventListener('mousedown', el.__onStart);
  el.addEventListener('touchstart', el.__onStart);
};

const directive = {
  inserted: bind,
  beforeMount: bind,
  unbind,
  unmounted: unbind
};

const install = Vue => {
  Vue.directive('resizable', directive);
};

export default { install };
