<template>
  <validation-observer ref="form" slim>
    <layout width="540">
      <template slot="header">
        Add geo group
      </template>

      <template>
        <v-form>
          <validation-provider
            v-if="geoTypes.length > 1"
            v-slot="{ errors }"
            name="Geo group type"
            rules="required"
          >
            <v-select
              v-model="state.geotype"
              :items="geoTypes"
              :loading="fetching"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Geo group type*"
            />
          </validation-provider>
          <validation-provider
            v-slot="{ errors }"
            name="Geo group name"
            rules="required"
          >
            <v-autocomplete
              v-model="state.geoId"
              :disabled="fetching"
              :items="state.geoObjects"
              item-text="name"
              item-value="id"
              :error-messages="errors"
              label="Geo group name*"
            />
          </validation-provider>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :disabled="fetching"
          :loading="loading"
          @click.stop="exec"
        >
          Add
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, watch } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectService } from '@/modules/objects/api';
import { landmarksService } from '@/modules/landmarks/api';
import { geozonesService } from '@/modules/geozones/api';
import { geotagsService } from '@/modules/geotags/api';
import { abcSort } from '@/compositions/sortBy';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'The {_field_} field is required'
});

export default {
  name: 'GeoObjectAdd',
  components: {
    Layout,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    onlyGeotags: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit }) {
    const geoTypes = props.onlyGeotags
      ? ['geotags']
      : ['landmarks', 'geozones', 'geotags'];
    const state = ref({
      geotype: geoTypes[0],
      geoId: '',
      geoObjects: []
    });
    const fetching = ref(false);
    const form = ref(null);

    watch(
      () => state.value.geotype,
      async () => {
        try {
          fetching.value = true;
          switch (state.value.geotype) {
            case 'landmarks': {
              state.value.geoObjects = await landmarksService.fetch();
              break;
            }
            case 'geozones': {
              state.value.geoObjects = await geozonesService.fetch();
              break;
            }
            case 'geotags': {
              state.value.geoObjects = await geotagsService.fetch();
              break;
            }
          }
          state.value.geoObjects = state.value.geoObjects.sort((a, b) =>
            abcSort(a.name, b.name)
          );
        } finally {
          fetching.value = false;
        }

        state.value.geoId = '';
      },
      {
        immediate: true
      }
    );

    const submit = async () => {
      const isSuccess = await form.value?.validate().catch(() => {});
      if (!isSuccess) return;

      await objectService.link(props.objectId, state.value.geoId);
      emit('confirm');
      emit('close');
    };

    const { loading, exec } = usePromise(submit, true);

    return {
      state,
      exec,
      loading,
      geoTypes,
      fetching,
      form
    };
  }
};
</script>
