<template>
  <validation-observer ref="form" slim>
    <layout width="540">
      <template slot="header"> {{ edit ? 'Edit' : 'Add' }} media </template>
      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            name="Media name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Media name*"
              :error-messages="errors"
            />
          </validation-provider>
          <validation-provider
            v-if="!edit"
            v-slot="{ errors }"
            name="Schema"
            rules="required"
          >
            <v-select
              v-model="state.schemaId"
              :items="schemas"
              :loading="schemasLoading"
              :error-messages="errors"
              item-text="name"
              item-value="id"
              label="Schema*"
            />
          </validation-provider>
          <form-switcher v-model="state.enabled" title="Enabled" />
          <media-input
            :id="state.id"
            v-model="state.media"
            rules="required"
            name="Media"
          />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          />
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="loading"
          @click.stop="exec"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, computed, onMounted } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { schemasService, objectService } from '@/modules/common/api';
import mediaComposition from '@/compositions/media';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import MediaInput from '@/components/_base/MediaInput';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'The {_field_} field is required'
});

export default {
  name: 'MediaEdit',
  components: {
    MediaInput,
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    linkedObjectId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { uploadMedia } = mediaComposition();
    const state = ref({
      id: '',
      name: '',
      media: null,
      description: '',
      schemaId: '',
      enabled: true
    });
    const form = ref(null);

    const edit = computed(() => props.linkedObjectId);

    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const submit = async () => {
      const isSuccess = await form.value?.validate().catch(() => {});
      if (!isSuccess) return;

      if (!edit.value) {
        await linkedObjectService
          .create({
            schemaId: state.value.schemaId,
            name: state.value.name,
            enabled: state.value.enabled,
            description: state.value.description
          })
          .then(async r => {
            const createdObjectId = r.data.createObject?.object.id;
            if (state.value.media) {
              await uploadMedia({
                file: state.value.media,
                id: createdObjectId
              });
            }
            await objectService.link(props.objectId, createdObjectId);
          });
      } else {
        if (state.value.media) {
          await uploadMedia({
            file: state.value.media,
            id: state.value.id
          });
        }
        await linkedObjectService.update(props.linkedObjectId, {
          name: state.value.name,
          enabled: state.value.enabled,
          description: state.value.description
        });
      }
      emit('close');
    };

    const { loading: schemasLoading, result: schemas } = usePromise(() =>
      schemasService.fetch('MEDIA')
    );

    onMounted(async () => {
      if (edit.value) {
        const device = await linkedObjectService.fetch(props.linkedObjectId);
        if (device) {
          state.value.name = device.name;
          state.value.id = device.id;
          state.value.description = device.description || '';
          state.value.enabled = device.enabled;
        }
      }
    });

    const { loading, exec } = usePromise(submit, true);

    return {
      submitBtnTitle,
      edit,
      state,
      exec,
      loading,
      schemasLoading,
      form,
      schemas: computed(() => schemas.value || [])
    };
  }
};
</script>
