import { apiClient } from '@/provider';
import notificationsScheme from './notifications.graphql';

type NotificationPatch = {
  objectId: string;
  objectName: string;
  tags: string[];
  message: string;
};

export default {
  update(data: NotificationPatch = {} as NotificationPatch) {
    return apiClient.mutate({
      mutation: notificationsScheme.sendNotification,
      variables: {
        input: {
          notification: data
        }
      }
    });
  }
};
