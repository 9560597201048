<template>
  <validation-observer ref="form" slim>
    <layout width="540">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <form-text v-if="!edit">
            The landmark will be created in the center of the current map
            position. Its position may be changed from the edit item menu.
          </form-text>
          <form-subheader title="General info" />
          <validation-provider
            v-slot="{ errors }"
            name="Landmark name"
            rules="required"
          >
            <v-text-field
              v-model.trim="state.name"
              label="Landmark name*"
              :error-messages="errors"
            />
          </validation-provider>
          <form-color-selector v-model="state.color" label="Color" />
          <validation-provider
            v-slot="{ errors }"
            name="Landmark radius"
            :rules="{ radius: 0, required: true, numeric: true }"
          >
            <v-text-field
              v-model="state.radius"
              label="Radius (m)*"
              :error-messages="errors"
            />
          </validation-provider>
          <form-subheader title="Position" />
          <v-autocomplete
            v-model="state.geotagId"
            :items="geotagList"
            item-text="name"
            item-value="id"
            label="Geotag"
          />
          <form-subheader title="Image" />
          <image-uploader
            :src="imageUrl"
            @change="handleChangeImage"
            @clear="handleClearImage"
          />
          <form-subheader title="Description" />
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
          />
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="loading"
          @click.stop="submit"
        >
          {{ submitBtnTitle }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { landmarkService } from '@/modules/landmarks/api';
import { imageService, objectPropertyService } from '@/modules/common/api';
import { mediaClient } from '@/provider';
import { min_value, required, numeric } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { useGeotags } from '@/modules/geotags/compositions/geotags';
import { geotagservice } from '@/modules/geotags/api';

extend('radius', {
  ...min_value,
  message: 'Radius must be >= 0'
});

extend('required', {
  ...required,
  message: 'The {_field_} field is required'
});

extend('numeric', numeric);

export default {
  name: 'LandmarkEdit',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    landmarkId: {
      type: String,
      default: ''
    },
    defaultLonLat: {
      type: Array,
      default: () => [0, 0]
    }
  },
  setup(props, { emit, root }) {
    const state = ref({
      schemaId: '',
      name: '',
      description: '',
      centerLat: 0,
      centerLon: 0,
      imageId: '',
      color: 'default',
      radius: 0,
      geotagId: ''
    });

    const landmark = ref({});
    const form = ref(null);
    const { list: geotagList, currentGeotagExceptAllItem } = useGeotags();

    const edit = computed(() => props.landmarkId);
    const title = computed(() =>
      edit.value ? 'Edit landmark' : 'Add landmark'
    );
    const submitBtnTitle = computed(() => (edit.value ? 'Save' : 'Add'));

    const imageLoaded = ref(false);

    const imageUrl = computed(() =>
      mediaClient.getImageUrl(state.value.imageId)
    );

    const handleChangeImage = async e => {
      console.log(e);
      state.value.imageId = await imageService.upload(e.target.files[0]);
    };

    const handleClearImage = () => {
      state.value.imageId.value = '';
      imageLoaded.value = false;
    };

    const handleSubmit = async () => {
      if (!(await form.value?.validate())) return;

      if (!edit.value) {
        // create new landmark
        landmark.value = await landmarkService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId: state.value.schemaId
        });

        const center = props.defaultLonLat;
        state.value.centerLon = center[0];
        state.value.centerLat = center[1];
      } else {
        await landmarkService.update(props.landmarkId, {
          name: state.value.name,
          description: state.value.description
        });
      }
      await objectPropertyService.updateValues([
        {
          id: landmark.value.positionCenter.id,
          value: {
            lon: Number(state.value.centerLon),
            lat: Number(state.value.centerLat)
          }
        },
        {
          id: landmark.value.positionRadius.id,
          value: Number(state.value.radius)
        },
        {
          id: landmark.value.infoImageId.id,
          value: state.value.imageId
        },
        {
          id: landmark.value.infoColor.id,
          value: state.value.color
        },
        {
          id: landmark.value.positionGeotagId.id,
          value: state.value.geotagId
        }
      ]);
      geotagservice.updateLinkToObject(
        landmark.value.id,
        landmark.value.positionGeotagId.value,
        state.value.geotagId
      );

      if (!edit.value) {
        root.$router.push({
          name: 'landmark_card',
          params: {
            landmarkId: landmark.value.id
          }
        });
      }

      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    onMounted(async () => {
      if (!edit.value) {
        state.value.schemaId = await landmarkService.fetchLandmarkSchemaId();
        if (!state.value.schemaId) {
          console.warn(`Can't fetch landmark schema`);
        }
        state.value.geotagId = currentGeotagExceptAllItem.value.id;
      } else {
        landmark.value = await landmarkService.fetch(props.landmarkId);

        const {
          positionCenter,
          positionRadius,
          positionGeotagId,
          infoImageId,
          infoColor,
          name,
          description
        } = landmark.value;

        state.value = {
          ...state.value,
          centerLat: positionCenter.value.lat,
          centerLon: positionCenter.value.lon,
          radius: positionRadius.value,
          geotagId: positionGeotagId.value,
          imageId: infoImageId.value,
          color: infoColor.value,
          name,
          description
        };
      }
    });

    return {
      state,
      loading,
      title,
      submitBtnTitle,
      submit,
      imageUrl,
      handleClearImage,
      handleChangeImage,
      edit,
      form,
      geotagList
    };
  }
};
</script>
