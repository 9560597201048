<template>
  <list-card
    ref="refList"
    :items="items"
    :list-actions="listActions"
    :group-actions="groupActions"
    :is-filter-active="isFilterActive"
    :sorting="sorting"
    :current-sort-by="currentSortId"
    title="Geozones"
    entity-type="geozone"
    @click:sort="handleSort"
    @click:filter-icon="handleFilterOpen"
    @selectedItemIds="handleSelectedItemIds"
    @active="handleActive"
  >
    <template #item="{select,index, ...props}">
      <geozone-list-item
        v-testid="`geozone-list-item-${index}`"
        v-bind="props"
        :actions="getItemActions(props.item)"
        :to="getItemRoute(props.item)"
        @select="select"
      />
    </template>
  </list-card>
</template>

<script>
import ListCard from '@/components/list-card';
import GeozoneListItem from './GeozoneListItem';
import { useObjectListActions } from './listActions';

import {
  computed,
  onBeforeUnmount,
  onMounted,
  ref,
  watch
} from '@vue/composition-api';

import { useSortBy } from '@/compositions/sortBy';
import { useGeozoneMenu } from '@/modules/geozones/compositions/geozoneMenu';
import { useGeozones } from '@/modules/geozones/compositions/geozones';
import sortingMenu from './sortingMenu';
import { useMap } from '@/compositions/map';
import {
  genMenuItemDelete,
  genMenuItemDisable,
  genMenuItemEnable
} from '@/utils/list-generators';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { usePopup } from '@/compositions/popup';
import { useFilter } from '@/compositions/filter';
import {
  GEOZONE_LIST_FILTER,
  useGeozoneFilters
} from '@/modules/geozones/compositions/geozone-filters';
import ObjectListItem from '@/modules/objects/ui/object-list/ObjectListItem.vue';

export default {
  name: 'Geozones',
  components: {
    ObjectListItem,
    ListCard,
    GeozoneListItem
  },

  setup(props, { root }) {
    const refList = ref(null);
    const popup = usePopup();
    const { list } = useGeozones();
    const {
      geozones,
      markers: { clearMarkers }
    } = useMap();

    const clearSelection = () => {
      refList.value && refList.value.clearSelection();
    };

    const getItemRoute = item => ({
      name: 'geozone_card',
      params: {
        geozoneId: item.id
      },
      query: root.$route.query
    });

    const selectedIds = ref([]);

    const getItemActions = entity => {
      return useGeozoneMenu({ entity });
    };

    const keyword = computed(() => {
      return root.$route?.query?.keyword;
    });

    const searchedList = computed(() => {
      return keyword.value
        ? list.value.filter(i => i.title.includes(keyword.value))
        : list.value;
    });

    const filters = useGeozoneFilters();

    const {
      filteredItems,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    } = useFilter(searchedList, filters, GEOZONE_LIST_FILTER);

    const { sortedItems, currentSortId, handleSort } = useSortBy(
      filteredItems,
      sortingMenu,
      'geozone'
    );

    const { listActions } = useObjectListActions();

    const handleSelectedItemIds = ids => {
      selectedIds.value = ids;
      geozones.selectGeozones(ids);
    };

    const handleActive = ({ id, active }) => {
      active ? geozones.selectGeozone(id) : geozones.unselectGeozone(id);
    };

    const groupActions = [
      genMenuItemDisable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Disable these objects?',
            onConfirm: async () => {
              await linkedObjectService
                .massiveUpdate(
                  selectedIds.value.map(item => ({
                    id: item,
                    enabled: false
                  }))
                )
                .finally(() => clearSelection());
            }
          }
        });
      }),
      genMenuItemEnable(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Enable these objects?',
            onConfirm: async () => {
              await linkedObjectService
                .massiveUpdate(
                  selectedIds.value.map(item => ({ id: item, enabled: true }))
                )
                .finally(() => clearSelection());
            }
          }
        });
      }),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete these geozones completely?',
            onConfirm: async () => {
              await linkedObjectService
                .massiveDelete(selectedIds.value.map(item => ({ id: item })))
                .finally(() => clearSelection());
            }
          }
        });
      })
    ];

    watch(
      () => filteredItems.value,
      value => {
        geozones.setGeozones(value);
      }
    );

    onMounted(() => {
      clearMarkers();
      geozones.setGeozones(list.value);
    });

    onBeforeUnmount(() => {
      geozones.clearGeozones();
    });

    return {
      getItemRoute,
      items: sortedItems,
      getItemActions,
      listActions,
      groupActions,
      sorting: sortingMenu,
      currentSortId,
      handleSort,
      handleSelectedItemIds,
      list,
      handleActive,
      refList,
      setFilter,
      currentFilter,
      isFilterActive,
      handleFilterOpen
    };
  }
};
</script>

<style></style>
