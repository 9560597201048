<template>
  <validation-observer ref="form" slim>
    <layout width="540" :dividers="false">
      <template slot="header">
        {{ title }}
      </template>

      <template>
        <v-form>
          <validation-provider
            v-slot="{ errors }"
            :rules="rules"
            :name="valueName"
          >
            <v-select
              v-if="select && !autocomplete"
              v-model="state"
              :clearable="!!select.clearable"
              :items="select.items"
              :item-text="select.itemText"
              :error-messages="errors"
              :item-value="select.itemValue"
              :label="valueName"
            />
            <v-autocomplete
              v-else-if="select && autocomplete"
              v-model="state"
              :items="select.items"
              :item-text="select.itemText"
              :item-value="select.itemValue"
              :error-messages="errors"
              :label="valueName"
              :clearable="!!select.clearable"
            />
            <template v-else>
              <v-text-field
                v-if="isNumber(typeId)"
                v-model.number="state"
                type="number"
                :error-messages="errors"
                :label="valueName"
              />
              <v-text-field
                v-else
                v-model="state"
                :error-messages="errors"
                :label="valueName"
              />
            </template>
          </validation-provider>
        </v-form>
      </template>
      <template slot="footer">
        <v-spacer />
        <v-btn text color="text-primary" @click.stop="$emit('close')">
          Cancel
        </v-btn>
        <v-btn
          text
          color="primary"
          depressed
          :loading="loading"
          :disabled="loading"
          @click.stop="handleConfirm"
        >
          {{ confirmText }}
        </v-btn>
      </template>
    </layout>
  </validation-observer>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api';
import layout from './PopupLayoutDefault';
import { isNumber } from '@/utils/getCurrentType';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

export default {
  name: 'PopupValueEdit',
  components: {
    layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    title: {
      type: String,
      default: 'Edit value'
    },
    valueName: {
      type: String,
      default: 'Name'
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Save'
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    select: {
      type: Object,
      default: undefined
    },
    autocomplete: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: String,
      default: ''
    },
    rules: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const state = ref(null);
    const form = ref(null);

    const handleCancel = () => {
      emit('close');
    };
    const handleConfirm = async () => {
      const isSuccess = await form.value?.validate().catch(() => {});
      if (!isSuccess) return;

      try {
        loading.value = true;
        await props.onConfirm(state.value);
        loading.value = false;
        emit('close');
      } finally {
        loading.value = false;
      }
    };
    onMounted(() => {
      state.value = props.value;
    });
    return {
      isNumber,
      handleConfirm,
      handleCancel,
      loading,
      state,
      form
    };
  }
};
</script>

<style lang="sass" scoped>
::v-deep
  .form-subheader:first-child
    margin-top: -16px
</style>
