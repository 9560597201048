<template>
  <layout width="540">
    <template slot="header">
      Send notification
    </template>

    <template>
      <validation-observer ref="form">
        <v-form>
          <v-combobox
            v-model="tags"
            :items="preFilled"
            multiple
            label="Tags"
            hide-selected
            clearable
            chips
          >
            <template v-slot:item="item">
              <div :data-test-tags="item.item">
                {{ item.item }}
              </div>
            </template>
            <template v-slot:selection="data">
              <v-chip small color="primary">
                {{ data.item }}
              </v-chip>
            </template>
          </v-combobox>
          <validation-provider
            v-slot="{ errors }"
            name="Message"
            rules="required"
          >
            <v-textarea
              v-model="message"
              rows="2"
              auto-grow
              data-test-message
              class="px-5"
              :error-messages="errors"
              label="Message*"
              clearable
            />
          </validation-provider>
        </v-form>
      </validation-observer>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Send
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import notificationService from '../api';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'The {_field_} field is required'
});

export default {
  name: 'SendNotificationsPopup',
  components: {
    Layout,
    ValidationObserver,
    ValidationProvider
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    objectName: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const { objectId, objectName } = props;
    const preFilled = ref([
      'application',
      'monitor',
      'triggered',
      'alert',
      'notice',
      'message'
    ]);
    const form = ref(null);
    const tags = ref(['monitor', 'message']);
    const message = ref('');

    const handleSubmit = async () => {
      const isSuccess = await form.value?.validate().catch(() => {});
      if (!isSuccess) return;

      await notificationService.update({
        objectId,
        objectName,
        message: message.value,
        tags: tags.value
      });
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    return {
      loading,
      submit,
      preFilled,
      tags,
      form,
      message
    };
  }
};
</script>
