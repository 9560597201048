<template>
  <base-list :items="items" subheader />
</template>

<script>
import {
  genAddItem,
  genMenuItemAccess,
  genMenuItemDisable,
  genMenuItemMute,
  genMenuItemEdit,
  genMenuItemShowHistory,
  genMenuItemDownload,
  genMenuItemDownloadNotifications,
  genMenuItemCopy,
  genMenuItemDelete,
  genMenuItemUnlink,
  genBaseItem,
  genMenuItemEnable
} from '@/utils/list-generators';

import { computed, inject, onMounted } from '@vue/composition-api';
import { useMedia } from '@/modules/medias/compositions/medias';
import mediasService from '@/modules/medias/api/medias';
import { objectService } from '@/modules/common/api';
import { usePopup } from '@/compositions/popup';
import { useSnackBar } from '@/compositions/snackBar';
import formatBytes from '@/utils/common/formatBytes';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { useCopy } from '@/compositions/copy';
import mediaComposition from '@/compositions/media';

export default {
  name: 'ObjectCardMedia',
  setup() {
    const objectId = inject('objectId');
    const { show } = useSnackBar();
    const popup = usePopup();
    const { getImageById } = mediaComposition();
    const { copyWithAlert } = useCopy();

    const { items, load, loading } = useMedia(objectId);

    const mediaActions = (id, relationId, enabled, isMediaUploaded) => [
      genMenuItemEdit(() => {
        popup.open({
          component: () =>
            import('@/modules/linked-objects/ui/LinkedObjectEdit.vue'),
          props: {
            typeObject: 'media',
            linkedObjectId: id
          }
        });
      }),
      genMenuItemAccess(undefined, { disabled: true }),
      enabled
        ? genMenuItemDisable(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Disable media?',
                onConfirm: () => linkedObjectService.disable(id)
              }
            });
          })
        : genMenuItemEnable(() => {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Enable media?',
                onConfirm: () => linkedObjectService.enable(id)
              }
            });
          }),
      genMenuItemMute(undefined, { disabled: true }),
      genMenuItemShowHistory(undefined, { disabled: true }),
      genMenuItemDownloadNotifications(undefined, { disabled: true }),
      genMenuItemDownload(
        () => {
          window.open(getImageById(id), '_blank');
        },
        {
          disabled: !isMediaUploaded
        }
      ),
      genMenuItemCopy(async () => copyWithAlert(id), {
        title: 'Copy UUID'
      }),
      genMenuItemDelete(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Delete media?',
            onConfirm: () => linkedObjectService.delete(id)
          }
        });
      }),
      genMenuItemUnlink(() => {
        popup.openConfirm({
          component: () => import('@/components/popup/PopupConfirmAction.vue'),
          props: {
            title: 'Unlink media?',
            onConfirm: () => linkedObjectService.unlink(relationId)
          }
        });
      })
    ];

    const genMediaItem = ({
      title,
      subTitle,
      url,
      id,
      relationId,
      enabled,
      isMediaUploaded,
      mediaType
    }) => {
      return genBaseItem({
        type: () => import('./MediaCard'),
        props: {
          title,
          subTitle,
          url,
          relationId,
          mediaType,
          actions: mediaActions(id, relationId, enabled, isMediaUploaded)
        }
      });
    };

    const handleAddNewDevice = () => {
      popup.open({
        component: () => import('@/modules/medias/ui/MediaEdit.vue'),
        props: {
          objectId
        }
      });
    };

    const handleAddExistingDevice = async () => {
      popup.open({
        component: () => import('@/components/popup/PopupValueEdit.vue'),
        props: {
          title: 'Link existing media',
          valueName: 'Media*',
          confirmText: 'Link',
          autocomplete: true,
          select: {
            items: await mediasService.fetchAll(),
            itemValue: 'id',
            itemText: 'name'
          },
          rules: 'required',
          onConfirm: value =>
            objectService.link(objectId, value).catch(error => show(error))
        }
      });
    };

    const mediaCards = computed(() => {
      const space = '\u00A0 \u00A0';
      return [
        ...items.value.map(item => {
          let subTitle = `${item.enabled ? 'Enabled' : 'Disabled'}`;
          if (item.mediaType) {
            subTitle += `${space}${item.mediaType}`;
          }
          if (item.mediaSize) {
            subTitle += `${space}${formatBytes(item.mediaSize)}`;
          }
          return genMediaItem({
            id: item.id,
            relationId: item.relationId,
            title: item.name,
            isMediaUploaded: item.mediaSize,
            mediaType: item.mediaType,
            enabled: item.enabled,
            subTitle,
            url: item.pictureUrl
          });
        }),
        genAddItem(
          {
            title: 'Add existing media'
          },
          { click: handleAddExistingDevice }
        ),
        genAddItem(
          {
            title: 'Add new media'
          },
          {
            click: handleAddNewDevice
          }
        )
      ];
    });

    onMounted(() => {
      load();
    });

    return {
      items: mediaCards,
      loading
    };
  }
};
</script>

<style></style>
