import { usePopup } from '@/compositions/popup';
import {
  genMenuItemEdit,
  genMenuItemExecute,
  genMenuItemShowHistory
} from '@/utils/list-generators';
import { linkedObjectService } from '@/modules/linked-objects/api';
import { getRpcArguments } from '@/provider/utils';
import router from '@/router';
import useHistoryStack, {
  componentAliases
} from '@/compositions/history-stack';
import { abcSort } from '@/compositions/sortBy';

export function useManagementItemMenu() {
  const popup = usePopup();
  const { getNextRoute } = useHistoryStack();

  return {
    genMenu: (managementItem, objectId) => {
      const { id, stateControlRpc, infoName } = managementItem;

      return [
        genMenuItemEdit(
          () => {
            popup.open({
              component: () => import('./ManagementItemEdit.vue'),
              props: {
                managementItemId: id,
                objectId
              }
            });
          },
          {
            title: 'Source'
          }
        ),
        genMenuItemExecute(async () => {
          const { sourceId, rpc, params } = stateControlRpc;
          let controls;
          if (sourceId) {
            controls = await linkedObjectService.fetchControlsByObjectId(
              sourceId
            );
            controls = controls.schema.schemaControls;
          } else {
            popup.openConfirm({
              component: () =>
                import('@/components/popup/PopupConfirmAction.vue'),
              props: {
                title: 'Execute',
                message:
                  "Can't execute the management item. Please set the source.",
                showCancelAction: false,
                confirmText: 'Close'
              }
            });
            return;
          }

          const controlArguments = getRpcArguments(controls, rpc);
          popup.open({
            component: () => import('@/components/popup/PopupValuesEdit.vue'),
            props: {
              title: rpc,
              confirmText: 'Execute',
              value: controlArguments
                .map(arg => ({
                  label: arg,
                  value: params[arg]
                }))
                .sort((a, b) => abcSort(a?.label, b?.label)),
              onConfirm: async value => {
                const params = value.reduce((acc, cur) => {
                  acc[cur.label] = cur.value;
                  return acc;
                }, {});

                return linkedObjectService.createControlExecution(
                  sourceId,
                  rpc,
                  params
                );
              }
            }
          });
        }),
        genMenuItemShowHistory(
          async () => {
            await router.push(
              getNextRoute({
                component: componentAliases.hcs,
                props: {
                  bench: 30,
                  ids: [],
                  monitoringItem: {
                    id: stateControlRpc.sourceId,
                    name: infoName,
                    type: 'controlItem'
                  }
                }
              })
            );
          },
          { disabled: false }
        )
      ];
    }
  };
}
