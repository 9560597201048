<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  hideIfEmpty
} from '@/utils/list-generators';
import { genAccessListFragment } from '@/modules/access/compositions/access-list-fragment';

import { computed, inject } from 'vue-demi';
import { useCopy } from '@/compositions/copy';

export default {
  name: 'LinkedObjectCardGeneral',
  setup() {
    const linkedObject = inject('linkedObject');
    const { copyWithAlert } = useCopy();

    const items = computed(() => {
      return [
        genSubheaderItem('General info'),
        genDefaultItem({
          title: linkedObject.value.schema.name,
          icon: '$schema'
        }),
        genDefaultItem(
          {
            title: linkedObject.value.id,
            icon: '$uuid'
          },
          {
            click: () => copyWithAlert(linkedObject.value.id)
          }
        ),
        genDefaultItem({
          title: linkedObject.value.name,
          icon: '$email'
        }),
        ...genAccessListFragment({
          readerGroup: `Readers: ${linkedObject.value.userGroupByReadergroup.groupName}`,
          userGroup: `Users: ${linkedObject.value.userGroupByUsergroup.groupName}`,
          editorGroup: `Editors: ${linkedObject.value.userGroupByEditorgroup.groupName}`
        }),
        ...hideIfEmpty(linkedObject.value.description, [
          genSubheaderItem('Description'),
          genMultilineItem({
            text: linkedObject.value.description
          })
        ])
      ];
    });

    return { items };
  }
};
</script>

<style></style>
