<template>
  <div class="layout-map">
    <v-sheet
      v-if="isReady && isAppDataLoaded"
      class="left-panel"
      :class="sidePanelClasses"
      elevation="4"
      tile
      v-resizable="{minWidth: 412, maxWidth: 828}"
      @resize="dispatchResize"
    >
      <router-view />
    </v-sheet>
    <div class="map-container" :class="{ mobile: $vuetify.breakpoint.mobile }">
      <map-component />
    </div>
  </div>
</template>

<script>
import MapComponent from './map';
import { useMap } from '@/compositions/map';
import { useAuth } from '@/modules/auth/api';
import {computed, watch} from '@vue/composition-api';
import { useToggleView } from '@/layouts/map/footerMenu/useToggleView';
import VResizable from '@/directives/resizer'
import Vue from "vue";

Vue.use(VResizable)

export default {
  name: 'LayoutMap',
  components: {
    MapComponent
  },
  setup(props, { root,  }) {
    const { isReady } = useMap();
    const { isAppDataLoaded } = useAuth();
    const { isMapState } = useToggleView(root.$vuetify);

    const dispatchResize = () => setTimeout(() => window.dispatchEvent(new Event('resize')), 20);

    watch(() => root.$vuetify.breakpoint.mobile, dispatchResize)
    const sidePanelClasses = computed(() => {
      return {
        'w-full': root.$vuetify.breakpoint.mobile,
        compact: root.$vuetify.breakpoint.mobile && isMapState.value
      };
    });

    return {
      isReady,
      isAppDataLoaded,
      sidePanelClasses,
      dispatchResize
    };
  }
};
</script>

<style lang="sass" scoped>
.layout-map
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    height: 100vh
    .left-panel
        display: flex
        flex-shrink: 0
        width: 412px
        height: 100%
        z-index: 1

        &.compact
          height: fit-content
    .map-container
        width: 100%
        height: 100%
    .w-full
        width: 100% !important
</style>
